// reds
$lightRed: #4f23ff;
$darkRed: #4f23ff;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

$backgroundLight: #f0f0f0;
$backgroundDark: #111111;
$leftFace: $lightBlue;
$rightFace: $lightBlue;
$topFace: $lightBlue;

$scale: 1;
$duration: 2.7s;
$timingFunction: ease;

.loader-container.dark {
  background: linear-gradient(90deg, rgba(17,17,19,1) 0%, rgba(17,17,19,1) 15%, rgba(31, 31, 37,1) 50%, rgba(17,17,19,1) 85%, rgba(17,17,19,1) 100%);

    .right,
    .left,
    .top {
        background: $backgroundDark;
        border: 1.5px solid $rightFace;
    }

    .loader {
        color: $lightRed;
    }
}

.loader-container {
  position:relative;
  height:100vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(237, 237, 239,1) 0%, rgba(237, 237, 239,1) 15%, rgba(220, 221, 225,1) 50%, rgba(237, 237, 239,1) 85%, rgba(237, 237, 239,1) 100%);

  &.closing {
    animation: loaderClose ease-out 300ms;
    .loader {
      animation: small ease-out 300ms;
    }
  }

  .loader {
    height: fit-content;
    color: $lightRed;
    font-size: 60px;
    opacity: 0.8;
    font-weight: bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    span {
      display: inline-block;
      animation: pulse 0.4s alternate infinite ease-in-out;

      &:nth-child(odd) {
        animation-delay: 0.4s;
      }
    }
    @keyframes pulse {
      to {
        transform: scale(0.8);
        opacity: 0.5;
      }
    }
    @keyframes loaderClose {
      to {
        opacity: 0;
      }
    }
    @keyframes small {
      to {
        transform: scale(0.1);
      }
    }
  }
}
