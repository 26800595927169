@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&family=Poppins:wght@200;300;400;700&family=Source+Code+Pro:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
/* 

// reds
$lightRed: #e22941;
$darkRed: #be3144;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
$bgRed: #950014;

// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

*/

*,html,body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* font-family: 'VT323', monospace; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  transition: 300ms;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0) !important; 
}
html {
  /* background-color: #950014; */
  background-color: #f0f0f0;
}
html.dark {
  /* background-color: #950014 !important; */
  background-color: #111111 !important;
}
html:not(.App.hidden) {
  animation: loading ease-in 800ms;
}
body {
  /* background-color: #950014; */
  background-color: #f0f0f0;
}
body.dark {
  /* background-color: #950014 !important; */
  background-color: #111111 !important;
}
code {
  font-family: 'Source Code Pro', monospace;
}
  .code {
  font-family: 'Source Code Pro', monospace !important;
}

a {
  text-decoration: none;
  color: #25252a;
}
p {
  height: fit-content;
}

.bold {
  font-weight: bold;
}

.dark:not(.follow-circle) {
  background-color: #111111 !important;
  color: #f0f0f0;
}

.dark a {
  color: #f0f0f0 !important;
}

.hidden {
  display: none;
}

.highlight {
  color: #950014 !important;
}
.highlight-s {
  -webkit-text-stroke: 1.5px #950014 !important;
}

@keyframes loading {
  0% {
    background-color: #f0f0f0 !important;
  }
  100% {
    background-color: #950014 !important;
  }
}
@keyframes loadingDark {
  0% {
    background-color: #080808 !important;
  }
  100% {
    background-color: #950014 !important;
  }
}