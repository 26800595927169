// reds
$lightRed: #4f23ff;
$darkRed: #4f23ff;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

$white: #ffffffa5;
$whiter: #FFFFFF;

$black: #222222a5;
$blacker: #222222;

.follow-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: transparent !important;
    border: 1px solid $black !important;
    border-radius: 50%;
    pointer-events: none;
    transition: border ease-in-out 300ms;
    z-index: 9999;

    &.scrolling {
      opacity: 0;
    }
    
    &.light {
        border-color: $white !important;

        .follow-circle-small{
            background-color: $whiter !important;
            svg {
              color: $lightRed;
            }
        }

      &#loading {
        border: none !important;
        border-color: white !important;
        border-right: 3px solid $lightRed !important;
        animation: circleLoad linear 800ms;
        .follow-circle-small {
          svg {
            opacity: 0;
          }
        }
      }
      
      &.contact,
      &.project,
      &.github,
      &.web,
      &.external {
        background-color: transparent !important;
        transition: 200ms;
        
        .follow-circle-small{
          background-color: $whiter !important;

          svg {
            color: $lightRed !important;
          }
          &.clicked {
            animation: none !important;
          }
        }
      }
      &.button {
        border-width: 2px !important;
        border-color: $lightRed !important;
        transition: 200ms;
        .follow-circle-small {
          display: none !important;
          &.clicked {
            animation: none !important;
          }
        }
        &.clicked {
          animation: none !important;
        }
      }
    }
    
    &.clicked {
        animation: pulseAnimation 500ms ease-in-out;
        &.button {
          animation: none !important;
          .follow-circle-small {
            display: none !important;
            &.clicked {
              animation: none !important;
            }
          }
        }
    }
    &.web.button {
      border-color: $lightRed !important;
      border-radius:  40px 0 0 40px !important;
    }
    &.github.button {
      border-color: $lightRed !important;
      border-radius:  0 40px 40px 0 !important;
     }

    &:not(.contact),
    &:not(.project),
    &:not(.github),
    &:not(.web),
    &:not(.external) {
      .follow-circle-small {
        width: 12px;
        height: 12px;
      }
    }
    &:not(.project) {
      svg {
        opacity: 0;
      }
    }

    .follow-circle-small {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        background-color: $black;
        border-radius: 50%;
        pointer-events: none;
        transition: 300ms ease-in-out;
        z-index: 99;
        
        &.clicked {
            animation: pulseAnimationSmall 800ms ease-in-out;
        }
    }

    &.contact,
    &.project,
    &.external { 
      transition: 200ms;

      .follow-circle-small {
        background-color: $whiter !important;
        transform: scale(2);
        width: 14px !important;
        height: 14px !important;
        
        &.clicked {
          animation: none !important;
        }
        
        svg {
          color: $lightRed !important;
          opacity: 1 !important;
        }
      }
    }

    &.github,
    &.web {
      .follow-circle-small {
        background-color: $whiter !important;
        transform: scale(2);
        padding: 4px;
        
        &.clicked {
          animation: none !important;
        }
        
        svg {
          color: $lightRed !important;
        }
      }
    }
    &.button {
      border-width: 2px !important;
      border-color: $lightRed !important;
      transition: 200ms;

      .follow-circle-small {
        display: none;
      }
    }

    &.black {
      border-color: $black !important;
      
      .follow-circle-small {
        background-color: $black !important;
      }
      &.contact,
      &.external,
      &.project {
        border-color: $black !important;

        .follow-circle-small{
          background-color: $lightRed !important;

          svg {
            color: #e6e6e6 !important;
          }
        }
      }
      &.button {
        border-color: $lightRed !important;
      }
      &#loading {
        border: none !important;
        border-color: $black !important;
        border-right: 3px solid $lightRed !important;
        animation: circleLoad linear 800ms;
        .follow-circle-small {
          svg {
            opacity: 0;
          }
          &.clicked {
            animation: none !important;
          }
        }
      }
    }
}

@keyframes pulseAnimation {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.6;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}
@keyframes pulseAnimationSmall {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(5.2);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
}
@keyframes circleLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

