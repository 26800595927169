// reds
$lightRed: #4f23ff;
$darkRed: #4f23ff;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

#projects_container {
    position: relative;
    // margin-top: 100vh;
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 60px;
    color: #25252a;
    background-color: #ededef !important;
    background: linear-gradient(90deg, rgba(237, 237, 239,1) 0%, rgba(237, 237, 239,1) 15%, rgb(220, 221, 225) 50%, rgba(237, 237, 239,1) 85%, rgba(237, 237, 239,1) 100%);

    animation: loading ease-in 800ms;

    #work {
        scroll-margin-top: 0;
        opacity: 0;

        &.in-v {
            opacity: 0.8;
        }
    }

    &.dark {
        color: #e6e6e6;
        background-color: #111113!important;
        background: linear-gradient(90deg, rgba(17,17,19,1) 0%, rgba(17,17,19,1) 15%, rgb(31, 31, 37) 50%, rgba(17,17,19,1) 85%, rgba(17,17,19,1) 100%);

        h3 {
            // font-weight: normal !important;
            -webkit-text-stroke: 0px #e6e6e6 !important;
            color: #e6e6e6;
            svg {
                color: $lightRed !important;
            }

        }
        .mobile-instr {
            color: #e6e6e6;
            -webkit-text-stroke: unset;
            &.w {
                opacity: 0;

                &.in-v {
                    opacity: 0.8;
                  }
            }
        }
        .all-projects {
            color: #e6e6e6;
        }
    }

    h3 {
        font-family: "Audiowide", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 100px 16px;
        padding-bottom: 0;
        line-height: 26px;
        font-size: 30px;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: normal;
        color: #333333;
        -webkit-text-stroke: 0px #333333 !important;

        svg {
            color: $lightRed !important;
            font-size: 20px;
        }
    }
    .mobile-instr {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 2px;
        color: #555;
        padding-top: 4px;
        padding-bottom: 40px;
        -webkit-text-stroke: unset;
    }
    .projects {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        align-items: start;
        grid-gap: 28px;
        margin: 0 auto;
        width: 95%;
        max-width: 900px;
        z-index: 8;
    }

    .all-projects {
        text-align: center;
        width: 100%;
        font-size: 16px;
        color: #222222;
        padding: 60px 0;
        font-weight: bold;
        text-transform: cap;
        transition: 200ms;
        letter-spacing: 2px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: $lightRed;
        &:hover {
            letter-spacing: 4px;
        }
    }

    @keyframes loading {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
    }
}
@media (max-width:1100px) {
    #projects_container {
        .projects {
            grid-template-columns: 1fr;
            max-width: 600px;
            // width: 95%;
        }
    }
}
// @media (max-height:630px) {
//     #projects_container {
//         margin-top: 20px;
//     }
// }

@media (max-width: 600px) {
    #projects_container {
        #work {
            scroll-margin-top: 64px;
        }
        h3 {
            font-size: 20px !important;
            line-height: 20px !important;
            padding-top: 32px !important;
        }
        .mobile-instr {
            font-size: 10px !important;
            padding-bottom: 32px !important;
        }
        .skills-container {
            .mobile-instr {
                padding-bottom: 0px !important;
            }
        }
    }
}
@media (pointer: coarse) {
    #projects_container {
        h3 {
            padding-bottom: 0 !important;
        }
        .mobile-instr {
            display: flex;
        }
    }

}
