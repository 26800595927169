// reds
$lightRed: #4f23ff;
$darkRed: #4f23ff;
$bgRed: #4c00a4;
// $darkerRed: #7e1928;
$darkerReder: #33007b;
$darkerRed: #31004a;
$lighterRed: #d897a1;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

$bgDark: #080808;

#about_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 64px;
    height: 100%;
    // width: 1100px;
    width: 100%;
    overflow: hidden;
    // position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    padding-top: 70px !important;
    color: #222222;
    // background-color: #e6e6e6;
    background-color: transparent;
    // background-image: url(../../assets/images/coding.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom left;
    animation: loading ease-in 800ms;
    // filter: invert(100%);
    // -webkit-filter: invert(100%);

    .content {
        filter: invert(100%);
        -webkit-filter: invert(100%);
        margin: 0;
        width: 100%;
        // width: 1100px;
        max-width: 1100px;
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 20px;

        .special {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: fit-content;
            z-index: 1;
            // height: 432px;
            height: fit-content;
            // padding: 40px 0;
            margin-bottom: 20px;
            // width: 314px;
            width: 100%;
            // background-color: #e6e6e6;
            background-color: transparent !important;
            filter: invert(100%);
            -webkit-filter: invert(100%);
            p {
                width: 100%;
                height: fit-content;
                line-height: 18px;
                &.big.first {
                    // background-color: #e6e6e6;
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    place-items: center;
                    span.letter {
                        font-family: 'Poppins', sans-serif;
                        font-weight: bold;
                        position: relative;
                        width: 78px !important;

                        &.faded {
                            opacity: 0.35;
                            color: transparent !important;
                        }
                        &.flip {
                            animation: flip 1.8s ease-in-out !important;
                        }
                    }
                }
                &.big.last {
                    // background-color: #e6e6e6;
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;;
                    place-items: center;
                    span.letter {
                        font-family: 'Poppins', sans-serif;
                        font-weight: bold;
                        position: relative;
                        width: 100px !important;

                        &.faded {
                            opacity: 0.35;
                            color: transparent !important;
                            animation: faded 1s ease-in;
                        }
                        &.flip {
                            animation: flip 1.8s ease-in-out !important;
                        }
                        .me-img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: 50%;
                            height: 50%;
                            border-radius: 50%;
                            background-image: url(../../assets/images/me-img.png);
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                }

                &.big span.letter{
                    text-align: center !important;
                    color: transparent;
                    text-align: left;
                    font-size: 120px;
                    line-height: 100px;
                    font-weight: bold;
                    white-space: initial;
                    word-wrap: break-word;
                    -webkit-text-stroke: 0px #222222;
                    span {
                        line-height: 8px;
                        font-size: 12px;
                        color: #25252a;
                        font-weight: 400;
                        -webkit-text-stroke: 0px;
                    }

                    &.highlight-s {
                        font-family: 'Poppins', sans-serif;
                        color: transparent !important;
                        font-weight: bold !important;
                        -webkit-text-stroke: 2px $lightRed !important;

                        &:before {
                            content: "";
                            z-index: -1;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background: linear-gradient(-45deg, #8f000005 0%, #b0333305 100% );
                            transform: translate3d(0px, 20px, 0) scale(0.95);
                            filter: blur(20px);
                            opacity: var(0.7);
                            transition: opacity 0.3s;
                            border-radius: inherit;
                        }
                        &::after {
                            content: "";
                            z-index: -1;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background: inherit;
                            border-radius: inherit;
                        }
                    }
                    &.blur-in {
                        animation: BlurAnimation 1s ease-in;
                    }
                    &.blur-out {
                        opacity: 0;
                        filter: blur(72px);
                        transition: 1s;
                    }
                }
                &.small {
                    font-size: 28px;
                    color: #222222;
                    padding: 4px 0;
                    text-align: center;
                    letter-spacing: 4px;
                    &.name.bold {
                        font-weight: normal !important;
                        font-family: "Audiowide", sans-serif;
                        letter-spacing: 2px;
                        color: #222222 !important;
                        -webkit-text-stroke: 0px #222222 !important;
                        // color: transparent;
                        // -webkit-text-stroke: 1px #222222 !important;
                        text-shadow:
                            0 0 7px #1111150f,
                            0 0 10px #1111150f,
                            0 0 21px #1111150f,
                            0 0 42px #4f23ff20,
                            0 0 82px #4f23ff20,
                            0 0 92px #4f23ff20,
                            0 0 102px #4f23ff20,
                            0 0 151px #4f23ff30;
                    }
                    &.hello {
                        opacity: 0.8;
                        font-size: 18px;
                        text-transform: lowercase;
                        &.shw {
                            animation: slideDo ease-in 600ms;
                        }
                    }
                    &.name.after {
                        transform: translateY(-20px);
                        opacity: 0;
                        transition: 1s;
                        transition-delay: 1s;
                        &.shw {
                            transform: translateY(0px);
                            opacity: 1;
                        }
                    }
                }
                &.big {
                    pointer-events: none;
                    user-select: none;
                    background-color: transparent !important;
                }
                &.bold {
                    font-weight: bold;
                }
            }
            .after-name {
                font-size: 14px !important;
                font-weight: 400;
                color: #222222 !important;
                -webkit-text-stroke: 0px !important;
            }
        }

        .divider {
            width: 90%;
            max-width: 314px;
            height: 8px;
            border-top: 0.5px solid #222222;
            margin-top: 10px;
            margin-bottom: 2px;
            z-index: 1;
            filter: invert(100%);
            -webkit-filter: invert(100%);
        }
        .hacker-cont {
            position: relative;
            width: 300px;
            height: 300px;
            margin: 12px 0;
            margin-left: 36px;
            margin-bottom: 20px;
            filter: invert(100%);
            -webkit-filter: invert(100%);
            .hacker {
                width: 300px;
                height: 300px;
                transition: 300ms;
                // content: url(../../assets/images/menobg.png);
                &.shadow {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: url(../../assets/images/menobg_stencil_b.png);
                }
                &:not(.shadow) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: url(../../assets/images/menobg.png);
                    filter: grayscale(0%);
                    transition: 300ms;
                    transition-delay: 300ms;
                    // background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1)), to(rgba(0, 0, 0, 0)));
                    &:hover {
                        filter: grayscale(0%) !important;
                        top: 50% !important;
                        left: 50% !important;
                        transform: translate(-50%, -50%) !important;
                    }
                    &.shw {
                        filter: grayscale(100%);
                        top: 52%;
                        left: 48%;
                        transform: translate(-52%, -48%);
                    }
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: fit-content;
            letter-spacing: 1px;
            gap: 4px;
            margin-bottom: 40px;
            width: 90%;
            max-width: 514px;
            z-index: 1;
            text-transform: lowercase;
            letter-spacing: 4px;
            filter: invert(100%);
            -webkit-filter: invert(100%);

            &.shw {
                animation: slideUe ease-in 600ms;
                min-height: 72px;
            }

            p {
                height: fit-content;
                font-size: 12px;
                line-height: 20px;
                font-weight: 300;
                min-height: 40px;
                &:hover{
                    cursor: default;
                }

                span.self-desc {
                    opacity: 0.8;
                    font-weight: normal;
                    min-height: 14px;
                }

                span.bold {
                    padding: 2px;
                    color: #222222 !important;
                }
            }
            p.more {
                color: $lightRed;
                font-weight: bold;
                letter-spacing: 1px;
                padding: 4px 8px;
                height: fit-content !important;
                min-height: unset !important;
                width: fit-content;
                margin: 0 auto;
                text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
                svg {
                    height: fit-content;
                    height: 10px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .contact-opts {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: fit-content;
                gap: 16px;
    
                a {
                    color: #25252a;
                    svg {
                        color: #25252a;
    
                    }
                }
            }
        }
    }
    &.dark {
        // background-color: $bgDark !important;
        background-color: transparent !important;
        color: #FFFFFF;
        filter: invert(0%);
        -webkit-filter: invert(0%);
        .content {
            filter: invert(0%);
            -webkit-filter: invert(0%);
        }
        .special {
            // background-color: #080808;
            filter: unset;
            -webkit-filter: unset;
            p {
                &.big span.letter{
                    color: transparent !important;
                    -webkit-text-stroke: 0px #f0f0f0;
                    span {
                        color: #f0f0f0;
                    }
                    &.blur-in {
                        animation: BlurAnimation 1s ease-in;
                    }
                    &.blur-out {
                        opacity: 0;
                        filter: blur(72px);
                        transition: 1s;
                    }
                }
                &.small {
                    color: #f0f0f0;
                    &.name.bold {
                        // color: transparent;
                        color: #e6e6e6 !important;
                        -webkit-text-stroke: 0px #f0f0f0 !important;
                        text-shadow:
                            0 0 7px #f0f0f021,
                            0 0 10px #f0f0f021,
                            0 0 21px #f0f0f021,
                            0 0 42px #4f23ff40,
                            0 0 82px #4f23ff40,
                            0 0 92px #4f23ff40,
                            0 0 102px #4f23ff40,
                            0 0 151px #4f23ff40;

                        }
                    }
                }
            .after-name {
                color: #f0f0f0 !important;
            }
        }
        .divider {
            border-top: 0.5px solid #f0f0f0;
            filter: unset;
            -webkit-filter: unset;
        }
        .hacker-cont {
            filter: unset;
            -webkit-filter: unset;
            .hacker {
                &.shadow {
                    content: url(../../assets/images/menobg_stencil_w.png);
                }
            }
        }

        .text {
            filter: unset;
            -webkit-filter: unset;
            p {
                span.bold {
                    color: #e6e6e6 !important;
                }
                span.self-desc {
                    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
                }
            }
            .contact-opts {
                a {
                    color: #e6e6e6;
                    svg {
                        color: #e6e6e6;
    
                    }
                }
            }
        }
        .scroll-ind {
            filter: invert(0%);
            -webkit-filter: invert(0%);
            .int {
                border-color: #e6e6e6 !important;
                svg {
                    color: #e6e6e6 !important;
                }

            }
        }
    }

    .scroll-ind {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 60px;
        width: 20px;
        // filter: invert(100%);
        // -webkit-filter: invert(100%);
        filter: unset;
        -webkit-filter: unset;
        opacity: 0.9;

        .int {
            border-radius: 12px;
            border: 1px solid #222;
            height: 23px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            animation: scroll-ind ease-in-out 4s infinite;
            svg {
                height: 20px;
                color: #222;
            }
        }
    }

    h1,p {
        font-weight: 300;
    }

    @keyframes scroll-ind {
        0% {
            height: 23px;
        }
        25% {
            height: 60px;
        }
        50% {
            height: 23px;
        }
        100% {
            height: 23px;
        }
    }
    @keyframes scroll-ind-small {
        0% {
            height: 23px;
        }
        25% {
            height: 40px;
        }
        50% {
            height: 23px;
        }
        100% {
            height: 23px;
        }
    }

    @keyframes loading {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
    }
    @keyframes BlurAnimation {
        0% {
            opacity: 0;
            filter: blur(70px);
        }
        50% {
            opacity: 1;
            filter: blur(10px);
        }
        100% {
            opacity: 1;
            filter: none;
        }
    }
    @keyframes BlurOutAnimation {
        0% {
            opacity: 1;
            filter: none;
        }
        50% {
            opacity: 1;
            filter: blur(10px);
        }
        100% {
            opacity: 0;
            filter: blur(70px);
        }
    }
    @keyframes faded {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.35;
        }
    }
    @keyframes flip {
        0% {
            transform: rotateY(0);
        }
        100% {
            transform: rotateY(360deg);
        }
    }
    @keyframes slideDo {
        0% {
            opacity: 0;
            transform: translateY(-30px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes slideUe {
        0% {
            opacity: 0;
            transform: translateY(30px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media (max-width: 500px) {
    #about_container {
        .content {
            .hacker-cont {
                width: 200px;
                height: 200px;
                .hacker {
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
}

@media (max-width:440px) {
    #about_container {
        padding: 25px;

        .content {
            .special {
                // width: fit-content;
                // height: fit-content;
                p.small {
                    &.hello {
                        font-size: 18px !important;
                        text-transform: lowercase;
                    }
                    &.name.after {
                        font-size: 22px !important;
                    }
                }
                p.big.first,
                p.big.last {
                    span.letter {
                        font-size: 98px !important;
                        width: 68px !important;
                        height: 80px !important;
                    }
                }
                p.big.last {
                    margin-bottom: 16px;
                    .me-img {
                        top: 60% !important;
                        left: 56% !important;
                    }
                }
                .after-name {
                    font-size: 12px !important;
                }
            }
        }
    }
}

@media (pointer: coarse) and (max-width:440px) {
    #about_container {
        padding: 25px;
        .content {
            .special {
                // width: fit-content;
                // height: fit-content;
                p.small {
                    &.hello {
                        font-size: 16px !important;
                    }
                    &.name.after {
                        font-size: 24px !important;
                    }
                    &.name.bold {
                        text-shadow: none !important;
                    }
                }
                p.big.first,
                p.big.last {
                    span.letter {
                        font-size: 98px !important;
                        width: 80px !important;
                        height: 80px !important;
                    }
                }
                p.big.last {
                    margin-bottom: 16px;
                    .me-img {
                        top: 60% !important;
                        left: 50% !important;
                    }
                }
                .after-name {
                    font-size: 12px !important;
                }
            }
            .hacker-cont .hacker {
                &:not(.shadow).shw {
                    &:hover {
                        top: 52% !important;
                        left: 48% !important;
                        transform: translate(-52%, -48%) !important;
                    }
                }
            }
        }
        &.dark .content .special .small.name.bold {
            text-shadow: none !important;
        }
    }
}
@media (pointer: coarse) and (max-width: 380px) {
    #about_container .content .special .after-name {
        font-size: 10px !important;
    }
}

@media (min-width: 800px) {
    #about_container .content .special {
        .hello {
            margin-bottom: 8px;
        }
        p.small.name.after {
            font-size: 36px !important;
        }
    }
}

@media (min-height:1000px) and (min-width: 600px) {
    #about_container {
        .content {
            justify-content: center;
            margin-bottom: 80px;
            .special {
                // height: 640px;
                // width: 465px;
                .big {
                    font-size: 180px !important;
                    line-height: 148px !important;
                }
            }
            .divider {
                width: 465px;
            }
        }
    }
}
@media (max-height:940px) {
    #about_container {
        .content {
            .text {
                p {
                    font-size: 10px;
                }
            }
        }
    }
}
@media (max-height:767px) {
    #about_container {
        padding-top: 50px;
        .content {
            margin-top: 0;
            .text {
                p {
                    font-size: 10px;
                }
            }
        }
    }
}
@media (max-height:740px) {
    #about_container {
        padding-top: 50px;
        .content {
            margin-top: 0;
            .text {
                p {
                    font-size: 10px;
                }
            }
        }
    }
}
@media (max-height:730px) {
    #about_container {
        .scroll-ind {
            height: 40px;
            .int {
                animation: scroll-ind-small ease-in-out 4s infinite !important;
            }
        }
    }
}
@media (max-height:700px) {
    #about_container {
        .scroll-ind {
            display: none;
        }
    }
}
@media (any-hover: none) {
    span.letter {
        &.blur-in {
            animation: none !important;
        }
        &.blur-out {
            opacity: 1 !important;
            filter: none !important;
            transition: none !important;
            animation: none !important;
        }
    }
}

// TYPEWRITER =====================
@keyframes typing {
    00.0%, 100% { content: ""; }
    00.5%, 99.5% { content: "A"; }
    // 01.0%, 20.0%, 38.0%, 99% { content: "A "; }
    01.5%, 19.5% { content: "A f"; }
    02.0%, 19.0% { content: "A fr"; }
    02.5%, 18.5% { content: "A fro"; }
    03.0%, 18.0% { content: "A fron"; }
    03.5%, 17.5% { content: "A front"; }
    04.0%, 17.0% { content: "A front-"; }
    04.5%, 16.5% { content: "A front-e"; }
    05.0%, 16.0% { content: "A front-en"; }
    05.5%, 15.5% { content: "A front-end"; }

    20.5%, 37.5% { content: "A b"; }
    21.0%, 37.0% { content: "A ba"; }
    21.5%, 36.5% { content: "A bac"; }
    22.0%, 36.0% { content: "A back"; }
    22.5%, 35.5% { content: "A back-"; }
    23.0%, 35.0% { content: "A back-e"; }
    23.5%, 34.5% { content: "A back-en"; }
    24.0%, 34.0% { content: "A back-end"; }
    
    38.5%, 98.5% { content: "A f"; }
    39.0%, 98% { content: "A fu"; }
    39.5%, 97.5% { content: "A ful"; }
    40.0%, 97% { content: "A full"; }
    40.5%, 96.5% { content: "A full-"; }
    41.0%, 96% { content: "A full-s"; }
    41.5%, 95.5% { content: "A full-st"; }
    42.0%, 95% { content: "A full-sta"; }
    42.5%, 94.5% { content: "A full-stac"; }
    43.0%, 94% { content: "A full-stack"; }

    // 43.5%, 93.5% { content: "A full-stack "; }
    44.0%, 93% { content: "A full-stack s"; }
    44.5%, 92.5% { content: "A full-stack so"; }
    45.0%, 92% { content: "A full-stack sof"; }
    45.5%, 91.5% { content: "A full-stack soft"; }
    46.0%, 91% { content: "A full-stack softw"; }
    46.5%, 90.5% { content: "A full-stack softwa"; }
    47.0%, 90% { content: "A full-stack softwar"; }
    47.5%, 89.5% { content: "A full-stack software"; }
    // 48.0%, 89% { content: "A full-stack software "; }
    48.5%, 88.5% { content: "A full-stack software d"; }
    49.0%, 88% { content: "A full-stack software de"; }
    49.5%, 87.5% { content: "A full-stack software dev"; }
    50.0%, 87% { content: "A full-stack software deve"; }
    50.5%, 86.5% { content: "A full-stack software devel"; }
    51.0%, 86% { content: "A full-stack software develo"; }
    51.5%, 85.5% { content: "A full-stack software develop"; }
    52.0%, 85% { content: "A full-stack software develope"; }
    52.5%, 84.5% { content: "A full-stack software developer"; }
    // 53.0%, 84% { content: "A full-stack software developer "; }
    53.5%, 83.5% { content: "A full-stack software developer b"; }
    54.0%, 83% { content: "A full-stack software developer ba"; }
    54.5%, 82.5% { content: "A full-stack software developer bas"; }
    55.0%, 82% { content: "A full-stack software developer base"; }
    55.5%, 81.5% { content: "A full-stack software developer based"; }
    // 56.0%, 81% { content: "A full-stack software developer based "; }
    56.5%, 80.5% { content: "A full-stack software developer based i"; }
    57.0%, 80% { content: "A full-stack software developer based in"; }
    // 57.5%, 79.5% { content: "A full-stack software developer based in "; }
    58.0%, 79% { content: "A full-stack software developer based in T"; }
    58.5%, 78.5% { content: "A full-stack software developer based in To"; }
    59.0%, 78% { content: "A full-stack software developer based in Tok"; }
    59.5%, 77.5% { content: "A full-stack software developer based in Toky"; }
    60.0%, 77.0% { content: "A full-stack software developer based in Tokyo"; }
    61.0%, 76.5% { content: "A full-stack software developer based in Tokyo."; }
    // 82 steps to write whole sentence
    // 47 steps to erase sentence
}
@keyframes typingEs {
    00.0%, 100% { content: ""; }
    00.5%, 99.5% { content: "U"; }
    01.0%, 99.0% { content: "Un"; }
    
    01.5%, 98.5% { content: "Un "; }
    02.0%, 98.0% { content: "Un d"; }
    02.5%, 97.5% { content: "Un de"; }
    03.0%, 97.0% { content: "Un des"; }
    03.5%, 96.5% { content: "Un desa"; }
    04.0%, 96.0% { content: "Un desar"; }
    04.5%, 95.5% { content: "Un desarr"; }
    05.0%, 95.0% { content: "Un desarro"; }
    05.5%, 94.5% { content: "Un desarrol"; }
    06.0%, 94.0% { content: "Un desarroll"; }
    06.5%, 93.5% { content: "Un desarrolla"; }
    07.0%, 93.0% { content: "Un desarrollad"; }
    07.5%, 92.5% { content: "Un desarrollado"; }
    08.0%, 92.0% { content: "Un desarrollador"; }
    08.5%, 24.0%, 38.5%, 91.5% { content: "Un desarrollador "; }

    09.0%, 23.5% { content: "Un desarrollador f"; }
    09.5%, 23.0% { content: "Un desarrollador fr"; }
    10.0%, 22.5% { content: "Un desarrollador fro"; }
    10.5%, 22.0% { content: "Un desarrollador fron"; }
    11.0%, 21.5% { content: "Un desarrollador front"; }
    
    24.5%, 38.0% { content: "Un desarrollador b"; }
    25.0%, 37.5% { content: "Un desarrollador ba"; }
    25.5%, 37.0% { content: "Un desarrollador bac"; }
    26.0%, 36.5% { content: "Un desarrollador back"; }

    39.0%, 91.0% { content: "Un desarrollador f"; }
    40.0%, 90.5% { content: "Un desarrollador fu"; }
    40.5%, 90.0% { content: "Un desarrollador ful"; }
    41.0%, 89.5% { content: "Un desarrollador full"; }
    41.5%, 89.0% { content: "Un desarrollador full-"; }
    42.0%, 88.5% { content: "Un desarrollador full-s"; }
    42.5%, 88.0% { content: "Un desarrollador full-st"; }
    43.0%, 87.5% { content: "Un desarrollador full-sta"; }
    43.5%, 87.0% { content: "Un desarrollador full-stac"; }
    44.0%, 86.5% { content: "Un desarrollador full-stack"; }
    44.5%, 86.0% { content: "Un desarrollador full-stack "; }
    
    45.0%, 85.5% { content: "Un desarrollador full-stack d"; }
    45.5%, 85.0% { content: "Un desarrollador full-stack de"; }
    46.0%, 84.5% { content: "Un desarrollador full-stack de "; }
    46.5%, 84.0% { content: "Un desarrollador full-stack de s"; }
    47.0%, 83.5% { content: "Un desarrollador full-stack de so"; }
    47.5%, 83.0% { content: "Un desarrollador full-stack de sof"; }
    48.0%, 82.5% { content: "Un desarrollador full-stack de soft"; }
    48.5%, 82.0% { content: "Un desarrollador full-stack de softw"; }
    49.0%, 81.5% { content: "Un desarrollador full-stack de softwa"; }
    49.5%, 81.0% { content: "Un desarrollador full-stack de softwar"; }
    50.0%, 80.5% { content: "Un desarrollador full-stack de software"; }
    50.5%, 80.0% { content: "Un desarrollador full-stack de software "; }
    51.0%, 79.5% { content: "Un desarrollador full-stack de software e"; }
    51.5%, 79.0% { content: "Un desarrollador full-stack de software en"; }
    52.0%, 78.5% { content: "Un desarrollador full-stack de software en "; }
    52.5%, 78.0% { content: "Un desarrollador full-stack de software en T"; }
    53.0%, 77.5% { content: "Un desarrollador full-stack de software en To"; }
    53.5%, 77.0% { content: "Un desarrollador full-stack de software en Tok"; }
    54.0%, 76.5% { content: "Un desarrollador full-stack de software en Toki"; }
    54.5%, 76.0% { content: "Un desarrollador full-stack de software en Tokio"; }
    55.0%, 75.5% { content: "Un desarrollador full-stack de software en Tokio."; }
}
@keyframes typingPt {
    00.0%, 100% { content: ""; }
    00.5%, 99.5% { content: "U"; }
    01.0%, 99.0% { content: "Um"; }
    
    01.5%, 98.5% { content: "Um "; }
    02.0%, 98.0% { content: "Um p"; }
    02.5%, 97.5% { content: "Um pr"; }
    03.0%, 97.0% { content: "Um pro"; }
    03.5%, 96.5% { content: "Um prog"; }
    04.0%, 96.0% { content: "Um progr"; }
    04.5%, 95.5% { content: "Um progra"; }
    05.0%, 95.0% { content: "Um program"; }
    05.5%, 94.5% { content: "Um programa"; }
    06.0%, 94.0% { content: "Um programad"; }
    06.5%, 93.5% { content: "Um programado"; }
    07.0%, 93.0% { content: "Um programador"; }
    07.5%, 92.5% { content: "Um programador "; }
    08.0%, 92.0% { content: "Um programador d"; }
    08.5%, 91.5% { content: "Um programador de"; }
    09.0%, 91.0% { content: "Um programador de s"; }
    09.5%, 90.5% { content: "Um programador de so"; }
    10.0%, 90.0% { content: "Um programador de sof"; }
    10.5%, 89.5% { content: "Um programador de soft"; }
    11.0%, 89.0% { content: "Um programador de softw"; }
    11.5%, 88.5% { content: "Um programador de softwa"; }
    12.0%, 88.0% { content: "Um programador de softwar"; }
    12.5%, 87.5% { content: "Um programador de software"; }
    13.0%, 29.0%, 44.5%, 87.0% { content: "Um programador de software "; }
    
    13.5%, 28.5% { content: "Um programador de software f"; }
    14.0%, 28.0% { content: "Um programador de software fr"; }
    14.5%, 27.5% { content: "Um programador de software fro"; }
    15.0%, 27.0% { content: "Um programador de software fron"; }
    15.5%, 26.5% { content: "Um programador de software front"; }

    30.0%, 44.0% { content: "Um programador de software b"; }
    30.5%, 43.5% { content: "Um programador de software ba"; }
    31.0%, 43.0% { content: "Um programador de software bac"; }
    31.5%, 42.5% { content: "Um programador de software back"; }

    46.0%, 86.5% { content: "Um programador de software f"; }
    46.5%, 86.0% { content: "Um programador de software fu"; }
    47.0%, 85.5% { content: "Um programador de software fu"; }
    47.5%, 85.0% { content: "Um programador de software ful"; }
    48.0%, 84.5% { content: "Um programador de software full"; }
    48.5%, 84.0% { content: "Um programador de software full-"; }
    49.0%, 83.5% { content: "Um programador de software full-s"; }
    49.5%, 83.0% { content: "Um programador de software full-st"; }
    50.0%, 82.5% { content: "Um programador de software full-sta"; }
    50.5%, 82.0% { content: "Um programador de software full-stac"; }
    51.0%, 81.5% { content: "Um programador de software full-stack"; }

    51.5%, 81.0% { content: "Um programador de software full-stack "; }
    52.0%, 80.5% { content: "Um programador de software full-stack e"; }
    52.5%, 80.0% { content: "Um programador de software full-stack em"; }
    53.0%, 79.5% { content: "Um programador de software full-stack em "; }
    53.5%, 79.0% { content: "Um programador de software full-stack em T"; }
    54.0%, 78.5% { content: "Um programador de software full-stack em Tó"; }
    54.5%, 78.0% { content: "Um programador de software full-stack em Tóq"; }
    55.0%, 77.5% { content: "Um programador de software full-stack em Tóqu"; }
    55.5%, 77.0% { content: "Um programador de software full-stack em Tóqui"; }
    56.0%, 76.5% { content: "Um programador de software full-stack em Tóquio"; }
    56.5%, 76.0% { content: "Um programador de software full-stack em Tóquio."; }
    57.0%, 75.5% { content: "Um programador de software full-stack em Tóquio."; }
}
@keyframes typingJa {
    00.0%, 100% { content: ""; }
    00.5%, 99.5% { content: "東"; }
    01.0%, 99.0% { content: "東京"; }
    01.5%, 98.5% { content: "東京を"; }
    02.0%, 98.0% { content: "東京を拠"; }
    02.5%, 97.5% { content: "東京を拠点"; }
    03.0%, 97.0% { content: "東京を拠点と"; }
    03.5%, 96.5% { content: "東京を拠点とす"; }
    04.0%, 21.0%, 37.0%, 96.0% { content: "東京を拠点とする"; }
    
    04.5%, 20.5% { content: "東京を拠点とするフ"; }
    05.0%, 20.0% { content: "東京を拠点とするフロ"; }
    05.5%, 19.5% { content: "東京を拠点とするフロン"; }
    06.0%, 19.0% { content: "東京を拠点とするフロント"; }
    06.5%, 18.5% { content: "東京を拠点とするフロントエ"; }
    07.0%, 18.0% { content: "東京を拠点とするフロントエン"; }
    07.5%, 17.5% { content: "東京を拠点とするフロントエンド"; }

    21.5%, 36.5% { content: "東京を拠点とするバ"; }
    22.0%, 36.0% { content: "東京を拠点とするバッ"; }
    22.5%, 35.5% { content: "東京を拠点とするバック"; }
    23.0%, 35.0% { content: "東京を拠点とするバックエ"; }
    23.5%, 34.5% { content: "東京を拠点とするバックエン"; }
    24.0%, 34.0% { content: "東京を拠点とするバックエンド"; }

    37.5%, 95.5% { content: "東京を拠点とするフ"; }
    38.0%, 95.0% { content: "東京を拠点とするフル"; }
    38.5%, 94.5% { content: "東京を拠点とするフルス"; }
    39.0%, 94.0% { content: "東京を拠点とするフルスタ"; }
    39.5%, 93.5% { content: "東京を拠点とするフルスタッ"; }
    40.0%, 93.0% { content: "東京を拠点とするフルスタック"; }
    40.5%, 92.5% { content: "東京を拠点とするフルスタックソ"; }
    41.0%, 92.0% { content: "東京を拠点とするフルスタックソフ"; }
    41.5%, 91.5% { content: "東京を拠点とするフルスタックソフト"; }
    42.0%, 91.0% { content: "東京を拠点とするフルスタックソフトウ"; }
    42.5%, 90.5% { content: "東京を拠点とするフルスタックソフトウェ"; }
    43.0%, 90.0% { content: "東京を拠点とするフルスタックソフトウェア"; }
    43.5%, 89.5% { content: "東京を拠点とするフルスタックソフトウェア開"; }
    44.0%, 89.0% { content: "東京を拠点とするフルスタックソフトウェア開発"; }
    44.5%, 88.5% { content: "東京を拠点とするフルスタックソフトウェア開発者"; }
    45.0%, 88.0% { content: "東京を拠点とするフルスタックソフトウェア開発者で"; }
    45.5%, 87.5% { content: "東京を拠点とするフルスタックソフトウェア開発者です"; }
    46.0%, 87.0% { content: "東京を拠点とするフルスタックソフトウェア開発者です。"; }
}
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }

  .typewriter {
    --caret: currentcolor;
    &::before {
        content: "";
        animation: typing 13.5s infinite;
        animation-delay: 1.5s;
    }
    &::after {
        content: "";
        border-right: 1ch solid var(--caret);
        animation: blink 0.5s linear infinite;
    }
    &.es::before {
        content: "";
        animation: typingEs 13.5s infinite;
    }
    &.pt::before {
        content: "";
        animation: typingPt 13.5s infinite;
    }
    &.ja::before {
        content: "";
        animation: typingJa 13.5s infinite;
    }
  }

  @media (prefers-reduced-motion) {
    .typewriter::after {
      animation: none;
    }
    
    @keyframes sequencePopup {
      0%, 100% { content: "A full-stack software developer based in Tokyo."; }
    }
    @keyframes sequencePopupEs {
      0%, 100% { content: "Un desarrollador full-stack de software en Tokio."; }
    }
    @keyframes sequencePopupPt {
      0%, 100% { content: "Um programador de software full-stack em Tóquio."; }
    }
    @keyframes sequencePopupJa {
      0%, 100% { content: "東京を拠点とするフルスタックソフトウェア開発者です。"; }
    }
  
    .typewriter::before {
      content: "A full-stack software developer based in Tokyo.";
      animation: sequencePopup 24s linear infinite;
    }
    .typewriter.es::before {
      content: "Un desarrollador full-stack de software en Tokio.";
      animation: sequencePopupEs 24s linear infinite;
    }
    .typewriter.pt::before {
      content: "Um programador de software full-stack em Tóquio.";
      animation: sequencePopupPt 24s linear infinite;
    }
    .typewriter.ja::before {
      content: "東京を拠点とするフルスタックソフトウェア開発者です。";
      animation: sequencePopupJa 24s linear infinite;
    }
  }

  .typewriter.loading::before {
    content: "";
    animation: none !important;
  }
// TYPEWRITER END =====================
