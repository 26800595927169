// reds
$lightRed: #4f23ff;
$darkRed: #be3144;
$bgRed: #950014;
$darkerReder: #670b0b;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

$bgDark: #080808;

#header_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    z-index: 999;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 8px 20px;
    background-color: #f0f0f0 !important;
    backdrop-filter: unset !important;
    animation: loading ease-in 800ms;

    &.b {
        backdrop-filter: blur(10px) !important;
    }
    &.hdr {
        background-color: transparent !important;
        &.b {
            background-color: #ededef88 !important;
            background: linear-gradient(90deg, rgba(237, 237, 239,0.533) 0%, rgba(237, 237, 239,0.533) 15%, rgba(220, 221, 225, 0.533) 50%, rgba(237, 237, 239,0.533) 85%, rgba(237, 237, 239,0.533) 100%);
        }
    }

    .header-logo {
        color: transparent;
        -webkit-text-stroke: 1px $lightRed !important;
        font-size: 30px;
        font-weight: bold;
        height: fit-content;
        opacity: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 24px;
        height: 24px;
        margin-bottom: 6px;
        // background-image: url(../../assets/images/menobg_stencil_logo_b.png);
        background-image: url(../../assets/images/logo_b.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.show {
            opacity: 0.8;
            transition: 500ms ease-in;
            // animation: show 500ms ease-in;
        }
    }
    .header-right {
        &.shw {
            animation: show ease-in 400ms;
        }
        .contact-opts {
            transition: 500ms ease-in;
            margin-top: 2px;
            pointer-events: none;
            opacity: 0;
            a {
                color: #25252a !important;
                svg {
                    color: #25252a !important;
                }
            }
            &.show {
                opacity: 1;
                pointer-events: all;
            }
        }
        &.show {
            svg {
                color: #25252a !important;
            }
            .contact-opts {
                pointer-events: none;
                opacity: 0;
                a {
                    color: #25252a !important;
                    svg {
                        color: #25252a !important;
                    }
                }
                &.show {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            .divider {
                border-left: 1px solid #25252a3f !important;
            }
        }
        .divider {
            transition: 500ms ease-in;
            border-left: 1px solid #25252a3f !important;
            opacity: 0;
                &.show {
                    opacity: 1;
                }
        }
    }
    svg {
        color: #25252a;

    }

    &.dark {
        background-color: $bgDark !important;
        &.b {
            backdrop-filter: blur(10px) !important;
        }

        .header-logo {
            // background-image: url(../../assets/images/menobg_stencil_logo_w.png);
            background-image: url(../../assets/images/logo_w.png);
        }
        &.hdr {
            background-color: transparent !important;
            &.b {
                background-color: #11111388!important;
                background: linear-gradient(90deg, rgba(17,17,19,0.533) 0%, rgba(17,17,19,0.533) 15%, rgba(31, 31, 37,0.533) 50%, rgba(17,17,19,0.533) 85%, rgba(17,17,19,0.533) 100%);
            }
        }
        button {
            color: #f0f0f0 !important;
        }
        svg {
            color: #FFFFFF !important;
        }

        .header-right {
            .contact-opts {
                a {
                    color: #FFFFFF !important;
                    svg {
                        color: #FFFFFF !important;
                    }
                }
            }

            &.show {
                svg {
                    color: #e6e6e6 !important;
                }
                .contact-opts {
                    a {
                        color: #e6e6e6 !important;
                        svg {
                            color: #e6e6e6 !important;
                        }
                    }
                }
                .divider {
                    border-left: 1px solid #f0f0f03f !important;
                }
            }
        }

        .divider {
            border-left: 1px solid #f0f0f03f !important;
        }
    }

    p {
        height: 30px;
        padding: 0 16px;
    }

    .header-right {
        display: flex;
        justify-content: center;
        align-items: center;

        .contact-opts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            gap: 16px;

            a {
                color: #25252a;
                svg {
                    color: #25252a;

                }
            }

            &.out {
                opacity: 0;
                transform: translateX(20px);
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            }
            &.entered {
                opacity: 1;
                transform: translateX(0);
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            }
        }
        button {
            height: 30px;
            width: 80px;
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            &.open {
                width: 40px;
                svg {
                    animation: rotate-in 300ms ease-in;
                }
            }
            &.closed {
                svg {
                    animation: rotate-out 200ms ease-in;
                }

            }
        }
        .divider {
            border-left: 1px solid #f0f0f03f;
            height: 28px;
            width: 12px;
            margin-left: 12px;
        }
        .info {
            margin-left: 16px;
            margin-bottom: 4px;
            &.mobile {
                margin-left: 2px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .toggle {
            margin-bottom: 4px;
            &:hover {
                cursor: pointer;
            }
            .invert {
                transform: rotate(180deg);
            }
        }
    }
    @keyframes rotate-in {
        0% {
            transform: rotate(90deg);
        }
        100% {
            transform: rotate(0);
        }
    }
    @keyframes rotate-out {
        0% {
            transform: rotate(-90deg);
        }
        100% {
            transform: rotate(0);
        }
    }
    @keyframes loading {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
    }
    @keyframes show {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.8;
        }
    }
}
