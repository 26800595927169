// reds
$lightRed: #4f23ff;
$darkRed: #be3144;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

#more {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff00;
    opacity: 0;
    transition: 400ms;

    .more-container {
        position: relative;
        box-sizing: border-box;
        background: rgb(237, 237, 239);
        background: linear-gradient(117deg, rgba(237, 237, 239,1) 0%, rgba(237, 237, 239,1) 57%, rgba(255,255,255,1) 75%, rgba(237, 237, 239,1) 100%);
        border: 1px solid #dcdde1;
        height: fit-content;
        // max-height: fit-content;
        min-height: 40%;
        width: 700px;
        max-width: 90%;
        border-radius: 8px;
        padding: 16px 36px;
        padding-bottom: 42px;
        transform: scale(0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
            backdrop-filter: unset;
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .more-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: fit-content;
            color: #333333;
            padding: 8px 0;

            svg {
                color: $lightRed;
                font-size: 22px;
            }
        }
        .more-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow-y: scroll;
            font-size: 14px;
            font-weight: 300;
            text-align: center;
            line-height: 28px;
            // flex-grow: 1;
            padding: 12px 0;
            transition: 100ms !important;
            span {
                display: block;
                height: fit-content;
                width: 100%;
                letter-spacing: 2px;
                &.title {
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 2px;
                }
            }
        }
        .close-more {
            padding: 8px 12px;
            color: $lightRed;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &.dark {
        background-color: #00000000 !important;
        .more-container {
            background: #111113;
            background: linear-gradient(117deg, rgba(17,17,19, 1) 0%, rgba(17,17,19, 1) 25%, rgba(31, 31, 37, 1) 70%, rgba(17,17,19, 1) 100%);
            border: 1px solid #1f1f25;

            .more-title {
                color: #e6e6e6;
            }
        }
    }

    &.on {
        opacity: 1;
        .more-container {
            transform: scale(1);
        }
    }
    @media (max-width: 600px) {
        .more-container {
            padding: 16px 18px;
        }
    }
    @media (max-width: 450px) {
        .more-container {
            max-width: 98%;
        }
    }
    @media (max-width: 400px) {
        .more-container {
            max-width: 98%;
            .more-content {
                font-size: 13px;
                span.title {
                    font-size: 13px;
                }
            }
        }
    }
    @media (max-height: 680px) {
        .more-container {
            max-width: 98%;
            max-height: 65%;
            .more-content {
                font-size: 11px;
                line-height: 22px;
                span.title {
                    font-size: 11px;
                }
            }
        }
    }
}
