/* 

// reds
$lightRed: #e22941;
$darkRed: #be3144;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;

// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

*/
#App {
  text-align: center;
  height: 100%;
  background-color: #f0f0f0;
  opacity: 1;
}

#App:not(.hidden) {
  animation: loading ease-in 800ms;
}


#App .app-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  z-index: 9998;
  opacity: 1;
}
#App.dark .app-overlay {
  background-color: #080808;
  transition: opacity 400ms;
}
#App .app-overlay.hide {
  animation: overlayClose 400ms;
}
#App .app-overlay.op-z {
  opacity: 0;
}
#App .app-overlay.bck {
  z-index: -9999;
}
p,span:hover {
  cursor: default;
}

.dark {
  background-color: #111111;
  color: #f0f0f0;
}

/* LOADING COMPONENT */

#loaderBg.loader-bg {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0 !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: loading ease-in 300ms;
  z-index: 9999;
}
#loaderBg.loader-bg.dark {
  position: relative;
  background-color: #111111 !important;
  background: linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(17,17,17,1) 15%, rgb(34, 34, 34) 50%, rgba(17,17,17,1) 85%, rgba(17,17,17,1) 100%) !important;
}
.no-v {
  opacity: 0 !important;
  transform: translateY(40px);
}
.in-v {
  animation: in-view ease-in 1s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes closing {
  to {
    opacity: 0;
  }
}
@keyframes overlayClose {
  to {
    opacity: 0;
  }
}

@keyframes in-view {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1 !important;
    transform: translateY(0);
  }
}