// reds
$lightRed: #4f23ff;
$darkRed: #be3144;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

#card {
    position: relative;
    background-color: transparent !important;
    animation: loading ease-in 800ms;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    // transition: 300ms !important;

    &:has(.selected) {
      height: 600px;
      min-height: 600px;
      max-height: 600px;
      .card-cover {
        .name-container {
          opacity: 0;
          // transform: translateY(25px);
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.hidden {
        opacity: 0;
    }

    .card-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 8px;
      padding: 12px;
      padding: 0;
      border-radius: 8px;
      filter: blur(0);
      transition: filter 700ms;
      overflow: hidden;

      .name-container {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        backdrop-filter: blur(0) !important;

        p {
          text-transform: capitalize;
          letter-spacing: 0px;
          font-weight: bold;
          font-size: 24px;
          color: #444444;
          -webkit-text-stroke: unset !important;
        }
        .stack {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          height: fit-content;
          margin-top: 6px;
          svg {
            font-size: 28px;
            color: #444444;
          }
        }
      }

      &.on {
        filter: blur(0px);
        border: 1px solid #dcdde1;
        background-color: #ededef;

        &.in-v {
          animation: card-in-view ease-in 800ms !important;
        }
        &.no-v {
          animation: card-out-of-view ease-in 800ms !important;
        }
      }

      &.off {
        filter: blur(20px);
        backdrop-filter: blur(20px);
        height: 100%;
      }
    }
    .project-pics {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 70%;
      z-index: 9;
      transition: 700ms;

      .pic {
        width: 100px;
        height: 190px;

        &.wide {
          width: 222px;
          height: 160px;
          border-radius: 4px;
        }

        &.chat1 {
          margin-right: 8px;
          border-radius: 4px;
        }
      }
    }

    .open-card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: #f0f0f0;
      background-size: 200%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 12px;
      border-radius: 8px;
      height: 100%;
      width: 100%;
      overflow: hidden;
      min-height: 600px;
      z-index: 99;
      opacity: 0;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      transition: 700ms !important;
      animation: bgMove 120s linear infinite;
      filter: blur(20px);

      .grain {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        z-index: 300;
        transform: translateZ(0);

        &:before {
            content: "";
            top: -10rem;
            left: -10rem;
            width: calc(100% + 20rem);
            height: calc(100% + 20rem);
            z-index: 9999;
            position: fixed;
            background-image: url(../../assets/images/grain_bg.png);
            -webkit-animation: noise 1s steps(2) infinite;
            animation: noise 1s steps(2) infinite;
            opacity: 0.15;
            pointer-events: none;
        }
      }

      .minimize {
        height: fit-content;
        width: 100%;
        z-index: 9999;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        p {
          &:hover {
            cursor: pointer;
          }
        }
      }

      a {
        opacity: 0;
        pointer-events: none;
        transform: translateY(40px);
        transition: 700ms !important;
      }

      &.selected {
        opacity: 1;
        filter: blur(0);

        &.cta {
          a {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
            z-index: 99;
            &:hover {
              cursor: pointer;
            }
            svg {
              color: #e6e6e6;
            }

            &.disabled {
              opacity: 0.5;
              pointer-events: none;
              &:hover {
                cursor: default;
              }
            }
          }
          .project-pics {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
          }
        }
      }
    }

    &.dark {
      .card-cover {
        border-color: #1f1f25;
        background: #131315;

        .name-container {
          p {
            text-transform: capitalize;
            letter-spacing: 0px;
            color: #e6e6e6;
            -webkit-text-stroke: unset !important;
          }
          .stack {
            svg {
              color: #e6e6e6;
            }
          }
        }
      }

      .open-card {
        background-color: #080808;

        .content {
          color: #e6e6e6 !important;
        }
      }

      .project-title {
        height: fit-content;
        color: #e6e6e6 !important;
        -webkit-text-stroke: unset !important;
      }
      .project-info {
        .cta {
          height: fit-content;
          a {
            border-color: #e6e6e6 !important;
            color: #e6e6e6 !important;
            svg {
              color: #e6e6e6 !important;
            }
          }
        }
      }
    }
    .project-title {
      height: fit-content;
      width: 100%;
      padding: 16px 0;
      font-size: 28px;
      text-align: center;
      color: #333333 !important;
      -webkit-text-stroke: unset !important;
      cursor: default;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      color: #222222;
      width: 100%;
      overflow-x: hidden;

      .project-info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        overflow: hidden;
         p {
          cursor: default;
         }

         .more {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:hover {
            cursor: default;
          }
         }
      }
    }

    &:first-child,
    &:nth-child(3),
    &:nth-child(5) {
      .card-cover {
        flex-direction: row;
        justify-content: space-evenly;
      }
      .content {
        flex-direction: row;
      }
    }
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      .card-cover {
        flex-direction: row-reverse;
        justify-content: space-evenly;
      }
      .content {
        flex-direction: row-reverse;
      }
    }

    .project-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      p {
        padding: 8px 8px 0 8px;
      }

      div {
        padding: 8px;

        .stack {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          padding: 20px 0;
          height: 70px;
          svg {
            font-size: 28px;
          }
        }

        .cta {
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0;
          height: fit-content !important;
          position: relative;
          a {
            padding: 12px 18px;
            color: #333333;
            border: 1px solid #333333;
            background-color: #ffffff09 !important;
            // transition: 100ms !important;
            backdrop-filter: blur(18px) !important;
            text-transform: uppercase;
            font-size: 14px;
            p.svg {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
              padding: 0;
              &:hover {
                cursor: pointer;
              }
            }
             svg {
              font-size: 18px !important;
              padding-bottom: 2px;
              color: #333333 !important;
              // transition: 100ms !important;
            }
            &:first-child {
              border-radius: 40px 0 0 40px !important;
              border-right: none !important;
            }
            &:nth-child(2) {
              border-radius: 0 40px 40px 0 !important;
              border-left: none !important;
            }
            &:hover {
              background-color: $lightRed !important;
              border-color: $lightRed !important;
              color: #e6e6e6 !important;
              svg {
                color: #e6e6e6 !important;
              }
            }
          }
        }
      }
    }

  @keyframes loading {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
  }
  @keyframes bgMove {
    0% {
        background-position: top left;
        background-size: 200%;
      }
    50% {
      background-position: bottom right;
      background-size: 250%;
    }
    100% {
      background-position: top left;
      background-size: 200%;
    }
  }
  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    0% {
      transform: translateX(-100px);
    }
  }
  @keyframes slideRight {
    0% {
      transform: translateX(-100px);
    }
    0% {
      transform: translateX(0);
    }
  }
  @keyframes slideLeft2 {
    0% {
      transform: translateX(100px);
    }
    0% {
      transform: translateX(0);
    }
  }
  @keyframes slideRight2 {
    0% {
      transform: translateX(0);
    }
    0% {
      transform: translateX(100px);
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100px);
    }
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes noise {
    to { transform: translate3d(-7rem,0,0) }
  }

  @keyframes noise {
      0% { transform: translate3d(0,9rem,0) }
      10% { transform: translate3d(-1rem,-4rem,0) }
      20% { transform: translate3d(-8rem,2rem,0) }
      30% { transform: translate3d(9rem,-9rem,0) }
      40% { transform: translate3d(-2rem,7rem,0) }
      50% { transform: translate3d(-9rem,-4rem,0) }
      60% { transform: translate3d(2rem,6rem,0) }
      70% { transform: translate3d(7rem,-8rem,0) }
      80% { transform: translate3d(-9rem,1rem,0) }
      90% { transform: translate3d(6rem,-5rem,0) }
      to { transform: translate3d(-7rem,0,0) }
  }

  @keyframes card-in-view {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes card-out-of-view {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(0.9);
    }
  }

  @media (max-width: 700px) {
    width: 84% !important;
    .open-card {
      height: 500px;
      min-height: 500px;
      max-height: 500px;
    }
    &:has(.selected) {
      width: 100% !important;
      height: 500px;
      min-height: 500px;
      max-height: 500px;

      .open-card {
        height: 500px;
        min-height: 500px;
        max-height: 500px;
      }
    }
    .card-cover {
      .name-container {
        p {
          font-size: 22px !important;
        }
        .stack {
          svg {
            font-size: 22px;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    height: 120px;
    .open-card {
      height: 450px;
      min-height: 450px;
      max-height: 450px;

      .project-title {
        padding-top: 0;
        font-size: 22px;
      }
      .content {
        justify-content: flex-start;
        height: fit-content;
        overflow: hidden;
        .project-info {
          height: fit-content;
          p {
            font-size: 11px;
          }
          .more {
            justify-content: flex-start;
            .stack {
              padding: 12px 0;
              height: fit-content;
              svg {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .project-pics {
      // bottom: -60px;
      height: fit-content;
      .pic {
        height: 140px;
        width: 76px;
        &.wide {
          height: 132px;
          width: 170px;
        }
      }
    }
    &:has(.selected) {
      height: 450px;
      min-height: 450px;
      max-height: 450px;

      .open-card {
        height: 450px;
        min-height: 450px;
        max-height: 450px;
      }
    }
    .card-cover {
      flex-direction: column !important;
      .name-container {
        // height: fit-content;
        // padding: 24px 0;
        p {
          font-size: 16px !important;
          letter-spacing: 1px !important;
          svg {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}
