// reds
$lightRed: #4f23ff;
$darkRed: #4f23ff;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

#info_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #ffffff00;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;

    &.open {
        display: flex;
        opacity: 1;
        animation: open ease-in 500ms;
    }
    &.closing {
        animation: close ease-in 300ms;
    }
    &.close {
        display: none;
        opacity: 0;
    }

    #info_container {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 80%;
        max-width: 400px;
        border: none;
        border-radius: 0;
        padding:32px;
        padding-top: 18px;
        background-color: #ededef85 !important;
        backdrop-filter: blur(10px);
        color: #25252a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-bottom: 40px;
        transition: 300ms;
        animation: open ease-in 300ms;

        &.mobile {
            width: 100%;
            height: 100%;
            max-width: 100%;
            margin-bottom: 0;
            padding-top: 18px;
            padding-bottom: 94px;

        }
        .toggles {
            height: fit-content;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;
            z-index: 99;
            width: 100%;
            svg {
                color: #222222;
                &:hover {
                    cursor: pointer;
                    transform: rotate(180deg);
                }
            }
            .toggle.dm {
                svg:hover {
                    animation: none !important;
                    transform: scale(1.2) rotate(180deg) !important;
                }
                .invert {
                    transform: rotate(180deg);
                }
            }
            .toggle.lang {
                svg:hover {
                    animation: none !important;
                    transform: scale(1.2) rotate(360deg) !important;
                }
            }
        }

        .nav {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: fit-content;
            gap: 20px;

            a, p.contact {
                font-family: "Audiowide", sans-serif;
                font-size: 1.1rem;
                text-transform: uppercase;
                font-weight: normal;
                letter-spacing: 1px;
                height: fit-content;
                color: #222222 !important;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                &:hover {
                    cursor: pointer;
                    letter-spacing: 2px;
                }

                svg {
                    font-size: 22px;
                }
            }
            .ext {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                height: fit-content;
                // gap: 20px
                a {
                    padding-bottom: 20px;
                }
            }
        }

        .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            gap: 20px;
            width: 100%;

            .languages-list {
                animation: open ease-in 500ms;
                position: absolute;
                top: 80px;
                right: 40px;
                height: fit-content;
                width: fit-content;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                border: 1px solid #cfd0d4;
                background: rgb(237, 237, 239);
                background: linear-gradient(117deg, rgba(237, 237, 239,1) 0%, rgba(237, 237, 239,1) 57%, rgba(255,255,255,1) 75%, rgba(237, 237, 239,1) 100%);
                border-radius: 4px;
                
                button {
                    border: none;
                    background-color: transparent;
                    color: #333333;
                    padding: 12px;
                    letter-spacing: 1px;
                    border-radius: 4px;
                    min-height: 44px !important;
                    &:hover {
                        background-color: $lightRed;
                        color: #f0f0f0 !important;
                    }
                }
            }
            p {
                height: fit-content;
                font-weight: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;
                font-size: 12px;
                letter-spacing: 2px;
                text-transform: lowercase;
                a.underline {
                    border-bottom: 1px solid $lightRed;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1px;
                    width: fit-content;
                }
            }
            .icons {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: fit-content;
                gap: 12px;

                .divider {
                    padding-top: 4px;
                    color: #222222;
                }

                svg {
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                    font-size: 24px;
                    // color: $lightRed;
                    color: #222222;
                }
            }
            .info-img {
                width: 32px;
                height: 32px;
                // border: 1.5px solid $lightRed;
                border-radius: 50%;
                // background-image: url(../../assets/images/tomando.gif);
                background-image: url(../../assets/images/logo_b.png);
                // background-size: 40px 40px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    &.dark {
        background-color: #00000000 !important;

        #info_container {
            background-color: #11111370 !important;


            .content {
                .languages-list {
                    border: 1px solid #333333;
                    background: #111113;
                    background: linear-gradient(117deg, rgba(17,17,19, 1) 0%, rgba(17,17,19, 1) 25%, rgba(31, 31, 37, 1) 70%, rgba(17,17,19, 1) 100%);
                    button {
                        color: #f0f0f0;
                    }
                }
                p {
                    color: #ababab !important;
                    a {
                        color: #ababab !important;
                        &.underline {
                            border-bottom: 1px solid $lightRed;
                        }
                    }
                }
                .icons {
                    .divider {
                        color: white;
                    }
                    svg {
                        // color: $lightRed;
                        color: white;
                    }
                }
            }
            .toggles {
                svg {
                    color: #e6e6e6;
                }
                .toggle.dm {
                    svg:hover {
                        transform: scale(1.2) !important;
                    }
                }
            }
            .nav {
                a, p {
                    color: #e6e6e6 !important;
                }
            }
            .info-img {
                border-color: $lightRed;
                background-image: url(../../assets/images/logo_w.png);
            }
        }

    }

    @media (max-width:440px) {
        #info_container {
            width: 90%;
        }
    }
    @keyframes open {
        0% {
            transform: translateX(800px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes close {
        0% {
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(800px);
            opacity: 0;
        }
    }
}
