// reds
$lightRed: #4f23ff;
$darkRed: #be3144;
// $darkerRed: #7e1928;
$darkerRed: #3e1017;
$lighterRed: #dfb5bb;
// blues
$lightBlue: #2979e2;
$darkBlue: #333fb0;
// $darkerBlue: #19317e;
$darkerBlue: #10153e;
$lighterBlue: #b5bbdf;

$animationSpeed: 30s;

@keyframes scroll-left {
	0% { transform: translateX(0); }
	50% { transform: translateX(-30%)}
	100% { transform: translateX(0)}
}
@keyframes scroll-right {
	0% { transform: translateX(-30%)}
	50% { transform: translateX(0); }
	100% { transform: translateX(-30%)}
}
@keyframes scroll-left-med {
	0% { transform: translateX(0); }
	50% { transform: translateX(-100%)}
	100% { transform: translateX(0)}
}
@keyframes scroll-right-med {
	0% { transform: translateX(-100%)}
	50% { transform: translateX(0); }
	100% { transform: translateX(-100%)}
}
@keyframes scroll-left-small {
	0% { transform: translateX(0); }
	50% { transform: translateX(-200%)}
	100% { transform: translateX(0)}
}
@keyframes scroll-right-small {
	0% { transform: translateX(-200%)}
	50% { transform: translateX(0); }
	100% { transform: translateX(-200%)}
}

@mixin white-gradient {
	background: linear-gradient(to right,  #22222200 0%,#22222200 100%);
}
@mixin black-gradient {
	background: linear-gradient(to right,  #22222200 0%,#22222200 100%);
}

#skills {
    scroll-margin-top: 64px;
}

.skills-container {
    background-color: transparent !important;
    width: 100%;
    opacity: 0;
    &.in-v {
      opacity: 0.8;
    }

    h3.title {
        font-family: "Audiowide", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 40px 16px !important;
        padding-bottom: 0 !important;
        text-align: center;
        width: 100%;
        color: #e6e6e6;
        -webkit-text-stroke: 0px #555555 !important;
    }
    .mobile-instr {
        display: flex !important;
        justify-content: center;
        align-items: center;
        color: #555;
        -webkit-text-stroke: #555 !important;
        padding-bottom: 16px !important;
        height: fit-content !important;

        svg {
            height: fit-content;
        }
    }

    .skills {
        position: relative;
        background-color: transparent !important;
        max-width: 1300px;
        margin: 0 auto;

        .skill-types {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 0 16px;
            p {
                color: #333333;
                width: fit-content;
                min-width: 230px;
                padding: 16px 12px;
                border: 1px solid transparent;
                border-radius: 8px;
                line-height: 26px;
                font-size: 22px;
                letter-spacing: 3px;
                text-transform: capitalize;
                font-weight: normal;
                color: $lightRed;
                opacity: 0.8;
                -webkit-text-stroke: 0px $lightRed !important;

                &.on {
                    opacity: 1;
                    font-size: 30px;
                    color: $lightRed;
                    font-weight: bold;
                    text-shadow:
                        0 0 7px #4f23ff39,
                        0 0 10px #4f23ff39,
                        0 0 21px #4f23ff39,
                        0 0 42px #4f23ff80,
                        0 0 82px #4f23ff80,
                        0 0 92px #4f23ff80,
                        0 0 102px #4f23ff80,
                        0 0 151px #4f23ff80;
                }

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }

            }
        }

        .code-container > div {
            height: fit-content;
        }

        .code-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            max-width: 500px;
            height: 0px;
            background-color: transparent;
            z-index: 9;
            &:has(.on) {
                height: fit-content;
            }

            //  & * {
                //     -webkit-text-stroke: unset !important;
                //  }
                .type {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                place-items: center;
                row-gap: 8px;
                opacity: 0;
                height: 0 !important;
                margin: 0;
                padding: 0;
                background: #ededef;
                backdrop-filter: blur(12px);
                border-radius: 6px;
                transition: 600ms;
                .p {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // margin-bottom: 8px;
                }
                svg {
                    color: #222222;
                }
                p {
                    color: #333333 !important;
                    font-size: 14px;
                    width: fit-content;
                    min-width: 50%;
                    line-height: 24px;
                    text-align: center;
                    -webkit-text-stroke: 0 #333333 !important;
                    height: 0 !important;
                    opacity: 0;
                }
                &.on {
                    margin: 0 auto;
                    padding: 24px 16px;
                    opacity: 1;
                    height: fit-content !important;
                    border: 1px solid #dcdde1;
                    p {
                        opacity: 1;
                        height: fit-content !important;
                    }
                }
            }
        }

        .frontend,
        .backend,
        .other {
            position: relative;
            padding: 16px 0;

            .skillset {
                background-color: transparent !important;
                position: relative;

                background: #e6e6e6;
                height: 40px;
                margin: auto;
                overflow:hidden;
                position: relative;
                width: 100%;

                &::before,
                &::after {
                    @include white-gradient;
                    content: "";
                    height: 60px;
                    position: absolute;
                    width: 200px;
                    z-index: 2;
                }

                &::after {
                    right: 0;
                    top: 0;
                    transform: rotateZ(180deg);
                }

                &::before {
                    left: 0;
                    top: 0;
                }

                .skillset-track {
                    display: flex;
                    align-items: center;
                    // width: calc(250px * 14);
                    width: 100%;
                    &.front {
                        animation: scroll-left $animationSpeed linear infinite;
                    }
                    &.back {
                        animation: scroll-right $animationSpeed linear infinite;
                    }
                    &.other {
                        animation: scroll-left $animationSpeed linear infinite;
                    }
                }

                .skill {
                    height: 40px;
                    width: 40px;
                    margin: 0 40px;

                    svg {
                        height: 40px;
                        width: 40px;
                        color: #555555;
                    }

                    p {
                        font-size: 10px;
                        font-weight: 300;
                        color: #555555;
                        letter-spacing: 1px;
                        width: 100%;
                        text-align: center;
                        transition: 300ms !important;
                    }
                }
            }
        }

    }

    &.dark {
        background-color: transparent !important;
        color: #222222;
        .title {
            color: #222222;
            -webkit-text-stroke: 0px #e6e6e6 !important;
        }
        .skills {
            background-color: transparent !important;
            .type {
                background: #131315;
                svg {
                    color: #f0f0f0;
                }
                p {
                    color: #e6e6e6 !important;
                    -webkit-text-stroke: 0 #e6e6e6 !important;
                }
                &.on {
                    border: 1px solid #1f1f25;
                }
            }
            .frontend,
            .backend,
            .other {
                .skillset {
                    background: #222222;
                    &::before,
                    &::after {
                        @include black-gradient;
                    }
                    .skill {

                        svg {
                            color: #e6e6e6;
                        }

                        p {
                            color: #e6e6e6;
                        }
                    }
                }
            }

        }
    }
    @media (max-width: 900px) {
        .skills {
            .skillset {
                height: 32px !important;
                .skill {
                    height: 30px !important;
                    width: 30px !important;
                    margin: 0 30px !important;

                    svg {
                        height: 30px !important;
                        width: 30px !important;
                    }
                }
            }
        }
    }
    @media (max-width:800px) {
        .skills {
            .skill-types {
                p {
                    font-size: 24px;
                    min-width: 200px;
                }
            }
        }
    }
    @media (max-width:700px) {
        .skills {
            .skill-types {
                p {
                    font-size: 20px;
                    min-width: 160px;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .skills {
            .frontend,
            .backend,
            .other {
                padding: 8px;
            }
            .code-container {
                .type.on {
                    padding: 16px 16px;
                }
            }
            .skillset {
                height: 24px;
                .skillset-track {
                    .skill {
                        svg {
                            height: 24px !important;
                            width: 24px !important;
                        }
                    }
                    &.front {
                        animation: scroll-left-med $animationSpeed linear infinite !important;
                    }
                    &.back {
                        animation: scroll-right-med $animationSpeed linear infinite !important;
                    }
                    &.other {
                        animation: scroll-left-med $animationSpeed linear infinite !important;
                    }
                }
            }
        }
    }
    @media (max-width:550px) {
        .skills {
            .skill-types {
                justify-content: center;
                gap: 0;
                p {
                    font-size: 20px;
                    min-width: 148px;
                    letter-spacing: 1px;
                }
            }
        }
    }
    @media (max-width: 460px) {
        .skills {
            .skillset {
                .skillset-track {
                    &.front {
                        animation: scroll-left-small $animationSpeed linear infinite !important;
                    }
                    &.back {
                        animation: scroll-right-small $animationSpeed linear infinite !important;
                    }
                    &.other {
                        animation: scroll-left-small $animationSpeed linear infinite !important;
                    }
                }
            }
            .code-container {
                .type {
                    p {
                        font-size: 12px;
                    }
                }
            }
            .skill-types {
                gap: 0;
                p {
                    font-size: 18px;
                    min-width: unset;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 1px $lightRed !important;
                    &.on {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    @media (max-width:400px) {
        .skills {
            .skill-types {
                gap: 0;
                p {
                    font-size: 15px;
                    min-width: unset;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 1px $lightRed !important;
                    &.on {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    @media (max-width:380px) {
        .skills {
            .skill-types {
                gap: 0;
                p {
                    font-size: 14px;
                    min-width: unset;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 1px $lightRed !important;
                    &.on {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (pointer: coarse) {
    #skills.skills-container {
        .mobile-instr {
        max-height: 20px !important;
        }
        .skill-types p.on {
            text-shadow: none !important;
        }
    }
}
